import React from 'react';
import stylesInput from '../shared/Input.module.css';
import styles from './InputText.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';

export default function InputText({
  label, placeholder, hint, icon,
  type,
  disabled, state,
  valueInput, onTextChange, submit
}) {
  if (disabled) {
    return (
      <div className={styles.disabled}>
        <p
          className={styles.label}
        >
          { label }
        </p>
        <div className={`${stylesInput.input} ${styles.inputDisabled}`}>
          <input
            type={(type === 'password') ? 'password' : 'text'}
            placeholder={placeholder}
            value={valueInput}
            onChange={onTextChange}
            disabled
          />
          {(icon) &&
          (
            <div className={styles.iconContainer}>
              { icon }
            </div>
          )}
        </div>
        <p
          className={`${styles.hint} ${styles.hintDisabled}`}
        >
          {hint}
        </p>
     </div>
    )
  }
  if (state === 'SUCCESS') {
    return (
      <div className={styles.success}>
        <p
          className={styles.label}
        >
          { label }
        </p>
        <div className={`${stylesInput.input} ${styles.inputSuccess}`}>
          <input
            type={(type === 'password') ? 'password' : 'text'}
            placeholder={placeholder}
            value={valueInput}
            onChange={onTextChange}
          />
          <FontAwesomeIcon className={styles.iconSuccess} icon={faCheck} />
          {(icon) &&
          (
            <div className={styles.iconContainer}>
              { icon }
            </div>
          )}
        </div>
        <p
          className={`${styles.hint} ${styles.hintSuccess}`}
        >
          {hint}
        </p>
     </div>
    )
  }
  if (state === 'ERROR') {
    return (
      <div className={`${styles.container} ${styles.error}`}>
        <p
          className={styles.label}
        >
          { label }
        </p>
        <div className={`${stylesInput.input} ${styles.inputError}`}>
          <input
            type={(type === 'password') ? 'password' : 'text'}
            placeholder={placeholder}
            value={valueInput}
            onChange={onTextChange}
          />
          <FontAwesomeIcon className={styles.iconError} icon={faTimes} />
          {(icon) &&
          (
            <div className={styles.iconContainer}>
              { icon }
            </div>
          )}
        </div>
        <p
          className={`${styles.hint} ${styles.hintError}`}
        >
          {hint}
        </p>
     </div>
    )
  }
  return (
    <div className={styles.container}>
      <p
        className={styles.label}
      >
        { label }
      </p>
      <div className={stylesInput.input}>
        <input
          type={(type === 'password') ? 'password' : 'text'}
          placeholder={placeholder}
          value={valueInput}
          onChange={onTextChange}
        />
        {(icon) &&
        (
          <div className={styles.iconContainer}>
            { icon }
          </div>
        )}
      </div>
      <p
        className={styles.hint}
      >
        {hint}
      </p>
   </div>
  )
}
