import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import photo from '../assets/beneficiaire_stock.png';

function Beneficiaire() {
  return (
    <section className="beneficiaire">
      <section className="container beneficiaire__main">
        <div className="home__title home__title--purple">
          <h6>
            Bénéficiaires
          </h6>
          <h2>
            Une solution adaptée à toutes les entreprises
          </h2>
          <div className="separator" />
        </div>
        <div className="beneficiaire__content">
          <div className="beneficiaire__slider">
            <img src={photo} alt="Toutes les entreprises peuvent être financées par AYOMI" />
          </div>
          <div className="beneficiaire__content__text">
            <p className="text">
              AYOMI facilite la levée de fonds aux entreprises de
              <b> toutes tailles </b>
              et de
              <b> tous secteurs </b>
              jusqu’à
              <b> 2 500 000€ </b>
              .
              <br />
              Nous sommes la solution à toutes les situations financières, notre seul critère :
              ne pas être en redressement judiciaire.
            </p>
            <div className="beneficiaire__grid">
              <div className="beneficiaire__grid__cell">
                <p className="beneficiaire__grid__cell__title">
                  <FontAwesomeIcon className="icon" icon={faCheck} />
                  Start-up
                </p>
                <p>
                  Vous n’avez pas encore les “métriques” vous permettant de
                  convaincre des fonds, business angels, ...
                  Ayomi est votre solution de financement.
                </p>
              </div>
              <div className="beneficiairet__grid__cell">
                <p className="beneficiaire__grid__cell__title">
                  <FontAwesomeIcon className="icon" icon={faCheck} />
                  TPE
                </p>
                <p>
                  Votre banque trouve que vous n’avez pas encore un nombre
                  d’années d’existence ou des bilans suffisants. Renforcez
                  vos fonds propres via notre solution.
                </p>
              </div>
              <div className="beneficiairet__grid__cell">
                <p className="beneficiaire__grid__cell__title">
                  <FontAwesomeIcon className="icon" icon={faCheck} />
                  Immobilier
                </p>
                <p>
                  Obtenez les fonds propres nécessaires pour débloquer votre
                  levier bancaire et ainsi lancer des projets plus conséquents
                  ou en plus grand nombre.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Beneficiaire;
