import React from 'react';

function VideoPlayer({ url, close }) {
  return (
    <section className="player">
      <button
        type="button"
        className="player__close"
        onClick={
          () => close()
        }
      >
        Fermer
      </button>
      <div className="player__responsive">
        <div className="videoContainer">
          <iframe
            className="video"
            src={url}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
    </section>
  );
}

export default VideoPlayer;
