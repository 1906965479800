import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import { postEmail } from '../../service/newsletterAPI';

import Button from './library/Button/Button';
import InputText from './library/Input/InputText/InputText';

function Footer() {
  const [mail, setMail] = useState('');
  const [isMailValid, setIsMailValid] = useState(true);
  const [servorError, setServorError] = useState(false);
  const [buttonState, setButtonState] = useState(0);
  // 0: default | 1: Loading | 2: valid

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const submitEmail = () => {
    if (isMailValid && mail) {
      setButtonState(1);
      postEmail(mail)
        .then(() => {
          setButtonState(2);
          setServorError(false);
        })
        .catch(() => {
          setButtonState(0);
          setServorError(true);
        });
    } else {
      setIsMailValid(false);
    }
  };

  const onEmailChange = (e) => {
    setIsMailValid(validateEmail(e.target.value));
    setMail(e.target.value);
  };

  const getButtonIcon = () => {
    if (buttonState === 1) return <FontAwesomeIcon className="icon" icon={faSpinner} spin />;
    if (buttonState === 2) return <FontAwesomeIcon className="icon" icon={faCheck} />;
    return undefined;
  };

  const getButtonMessage = () => {
    if (buttonState === 1) return 'Envoi...';
    if (buttonState === 2) return 'Vous êtes inscrit';
    return "Je m'inscris";
  };

  const getInputHint = () => {
    if (!isMailValid) return "L'adresse Email est incorrect";
    if (buttonState === 2) return 'Vous êtes inscrit !';
    return '';
  };

  const getInputState = () => {
    if (!isMailValid) return 'ERROR';
    return '';
  };

  return (
    <footer className="footer">
      <div className="container footer__grid">
        <div>
          <p className="logo--ayomi"> Ayomi </p>
          <p>
            La première intelligence artificielle qui permet de lever
            des fonds grâce à son réseau
          </p>
          <div
            className="footer__social"
          >
            <a
              className="footer__social__icon"
              href="https://www.linkedin.com/company/ayomi"
            >
              <FontAwesomeIcon className="icon" icon={faLinkedinIn} />
            </a>
            <a
              className="footer__social__icon"
              href="https://www.facebook.com/Ayomi.IA/"
            >
              <FontAwesomeIcon className="icon" icon={faFacebookF} />
            </a>
          </div>
        </div>
        <div className="footer__column">
          <p className="footer__title">À Propos</p>
          <a href="/solution/">Notre solution</a>
          <a href="/faq/">FAQ</a>
          <a href="/commentInvestir/">Comment investir ?</a>
          <a href="/legal/">Mentions Légales</a>
        </div>
        <div className="footer__column">
          <p className="footer__title">Investir</p>
          <a href="/compte/connexion/">Accéder au suivi</a>
        </div>
        <div className="footer__column">
          <p className="footer__title">Utiliser Ayomi</p>
          <a href="/cofunding/ipoome/registerevent/#/">Utiliser les services techniques d’Ayomi</a>
        </div>
        <div>
          <p className="footer__title">Newsletter</p>
          <InputText
            label="Inscrivez vous à notre newsletter"
            placeholder="Votre adresse email"
            valueInput={mail}
            onTextChange={(e) => onEmailChange(e)}
            state={getInputState()}
            hint={getInputHint()}
          />
          <Button
            label={getButtonMessage()}
            type="secondary"
            size="medium"
            disabled={!isMailValid}
            onButtonClick={submitEmail}
            icon={getButtonIcon()}
          />
          {
            (servorError)
            && (
              <p>
                Oups ! Nous n&apos;avons pas pu vous inscrire à notre newsletter.
                N&apos;hésitez pas à nous contacter.
              </p>
            )
          }
        </div>
      </div>
    </footer>
  );
}

export default Footer;
