import React from 'react';
import bgMint from '../../assets/contact_background_mint.svg';
import bgPurple from '../../assets/contact_background_purple.svg';

function Contact() {
  return (
    <section className="container contact">
      <div className="contact__section contact__section--blue">
        <div className="contact__section__content">
          <h4>
            Encore des questions ?
          </h4>
          <p>
            Notre équipe d’experts est à votre disposition pour répondre à vos questions
          </p>
          <button
            type="button"
            className="button button--purple"
            onClick={() => { document.location = 'https://calendrier.ayomi.fr/#/sales/Karim/gh/?source=Home'; }}
          >
              Nous appeler
          </button>
        </div>
        <img className="contact__background" src={bgPurple} alt="background" />
      </div>
      <div className="contact__section contact__section--green">
        <div className="contact__section__content">
          <h4>
            Prêt à nous rejoindre ?
          </h4>
          <p>
            Prenez rendez-vous avec un de nos conseillers, qui vous accompagnera.
          </p>
          <button
            type="button"
            className="button button--primary"
            onClick={() => { document.location = 'https://ayomi.fr/cofunding/ipoome/registerevent/#/'; }}
          >
            Débuter mon financement
          </button>
        </div>
        <img className="contact__background" src={bgMint} alt="background" />
      </div>
    </section>
  );
}

export default Contact;
