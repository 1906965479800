import React from 'react';
import Header from '../components/Header2';
import Social from '../components/Social';
import Solution from '../components/Solution';
import Beneficiaire from '../components/Beneficiaire';
import Processus from '../components/Processus';
import Temoignage from '../components/Temoignage';

function Home({ launchVideo }) {
  return (
    <main>
      <Header />
      <Social />
      <Solution />
      <Beneficiaire />
      <Processus />
      <Temoignage start={launchVideo} />
    </main>
  );
}

export default Home;
