import React from 'react';
import styles from './Button.module.css';

export default function Button({label, icon, type, size, disabled, inactive, onButtonClick}) {
  if (disabled) {
    return (
      <button
        type="button"
        className={`${styles.button} ${styles[size]} ${styles[`${type}--disabled`]}`}
        onClick={onButtonClick}
        disabled
      >
        { (icon) && (<span className={styles.icon}> {icon} </span>)}
        { label }
      </button>
    );
  }
  if (inactive) {
    return (
      <button
        type="button"
        className={`${styles.button} ${styles[size]} ${styles[`${type}--inactive`]}`}
        onClick={onButtonClick}
      >
        { (icon) && (<span className={styles.icon}> {icon} </span>)}
        { label }
      </button>
    );
  }
  return (
    <button
      type="button"
      className={`${styles.button} ${styles[type]} ${styles[size]}`}
      onClick={onButtonClick}
    >
      { (icon) && (<span className={styles.icon}> {icon} </span>)}
      { label }
    </button>
  );
}
