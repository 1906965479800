import React, { useState } from 'react';
import NavBar from './components/ui/NavBar';
import Footer from './components/ui/Footer';
import Contact from './components/ui/Contact';
import VideoPlayer from './components/ui/VideoPlayer';
import Home from './views/Home';
import './index.scss';

function App() {
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoURL, setVideoURL] = useState(null);

  const launchVideo = (url) => {
    setVideoURL(url);
    setShowVideoPlayer(true);
    document.body.style.overflow = 'hidden';
  };

  const closePlayer = () => {
    setShowVideoPlayer(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <div className="App">
      {
        (showVideoPlayer && videoURL)
        && (<VideoPlayer url={videoURL} close={closePlayer} />)
      }
      <NavBar />
      <Home launchVideo={launchVideo} />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
