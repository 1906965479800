import React from 'react';

import accompagnement from '../assets/accompagnement.svg';
import time from '../assets/time.svg';
import beneficiaire from '../assets/beneficiaire.svg';
import analyse from '../assets/analyse.svg';
import ia from '../assets/ia.svg';
import financeFile from '../assets/financeFile.svg';

function Solution() {
  return (
    <section className="container solution">
      <div className="home__title home__title--red">
        <h6>
          Avantages
        </h6>
        <h2>
          Une solution de financement unique
        </h2>
        <div className="separator" />
      </div>
      <div className="solution__grid">
        <div className="solution__grid__cell">
          <img src={accompagnement} alt="accompagnement" />
          <p>
            Un accompagnement personnalisé
          </p>
        </div>
        <div className="solution__grid__cell">
          <img src={time} alt="accompagnement" />
          <p>
            Une levée de fond en moins de deux mois
          </p>
        </div>
        <div className="solution__grid__cell">
          <img src={beneficiaire} alt="accompagnement" />
          <p>
            Une solution pour tous vos besoins de financement
          </p>
        </div>
        <div className="solution__grid__cell">
          <img src={analyse} alt="accompagnement" />
          <p>
            Une facturation essentiellement au succès
          </p>
        </div>
        <div className="solution__grid__cell">
          <img src={financeFile} alt="accompagnement" />
          <p>
            Aucune caution à fournir
          </p>
        </div>
        <div className="solution__grid__cell">
          <img src={ia} alt="accompagnement" />
          <p>
            Une IA qui identifie les potentiels investisseurs
          </p>
        </div>
      </div>
    </section>
  );
}

export default Solution;
