import axios from 'axios';
import axiosRetry from 'axios-retry';
import domain from '../../global.js';

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {

    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
    // Get cookie for IE (There are two different csrftoken in IE)
    if (navigator.appName === 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        // Get the last csrftoken for IE
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        }
      }
    }
    // console.log(cookieValue)
  }
  return cookieValue;
}
axios.defaults.headers.put['X-CSRFToken'] = getCookie('csrftoken');
axios.defaults.headers.post['X-CSRFToken'] = getCookie('csrftoken');

const axiosMockClient = axios.create({
  baseURL: domain,
  timeout: 5000,
});

axiosRetry(axiosMockClient, { retries: 3 });

export default axiosMockClient;
