import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import freedgeBeautyCover from '../assets/freedgebeautyCover.jpg';
import freedgeBeautyLogo from '../assets/freedgeBeautyLogo.png';
import espacielCover from '../assets/espacielCover.jpg';
import espacielLogo from '../assets/Espaciel.jpeg';
import franceDuckTourLogo from '../assets/franceducktourlogo.png';
import franceDuckTourCover from '../assets/fdts.JPG';
import calimedCover from '../assets/calimed.jpg';
import calimedLogo from '../assets/logo-calimed.svg';
import crowdCover from '../assets/crowd.jpg';
import crowdLogo from '../assets/crowdlogo.png';

function Header() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [nextSlide, setNextSlide] = useState(1);
  const [changing, setChanging] = useState(true);
  const slides = [
    {
      name: 'Crowd Cooks',
      cover: crowdCover,
      logo: crowdLogo,
      quote: "On se concentre sur son réseau à soi, je pense que c'est ce qui fait la force d'Ayomi",
    },
    {
      name: 'France Duck Tour',
      cover: franceDuckTourCover,
      logo: franceDuckTourLogo,
      quote: "Ceux qui investiront avec vous sont vraiment des gens qui ont la capacité d'investir, et qui ne l'auraient pas fait s'ils ne l'avaient pas su",
    },
    {
      name: 'CALIMED Santé',
      cover: calimedCover,
      logo: calimedLogo,
      quote: "Les premières personnes à faire confiance, sont les personnes qui croient dans les dirigeants et donc en leur projet",
    }
  ];
  useEffect(() => {
    let timer = null;
    const interval = window.setInterval(() => {
      setChanging(true);
      timer = window.setTimeout(() => {
        setChanging(false);
        const current = currentSlide;
        if (currentSlide < slides.length - 1) {
          setCurrentSlide(current + 1);
          if (nextSlide < slides.length - 1) {
            setNextSlide(current + 2);
          } else {
            setNextSlide(0);
          }
        } else {
          setCurrentSlide(0);
          setNextSlide(1);
        }
      }, 300);
    }, 3000);
    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  });
  return (
    <header className="header2">
      <img className="header2--responsive" src={slides[currentSlide].cover} alt={slides[currentSlide].name} />
      <section className="header2__text">
        <section className="header2__text__column">
          <h1>
            Se financer n’a jamais été aussi rapide !
          </h1>
          <p>
            Envie de faire grandir votre entreprise ? Ayomi est votre
            solution de financement pour tous vos besoins de 20K€ à 2.5M€
          </p>
          <button
            type="button"
            className="button button--primary"
            onClick={() => { document.location = 'https://calendrier.ayomi.fr/#/sales/Karim/gh/?source=Home'; }}
          >
            Prendre un rendez-vous
          </button>
        </section>
      </section>
      <section className="header2__img">
        <img className="imgContainer" src={slides[currentSlide].cover} alt="Freedge Beauty" />
        <div className="header2__caption">
          <img
            className="caption__logo"
            src={slides[currentSlide].logo}
            alt={slides[currentSlide].name}
          />
          <div className="home__title home__title--red header2__caption--text">
            <h6>
              {slides[currentSlide].name}
            </h6>
            <p>
              {
                (slides[currentSlide].quote)
                  ? `"${(slides[currentSlide].quote)}"`
                  : `${slides[currentSlide].name} s'est financé avec Ayomi !`
              }
            </p>
          </div>
        </div>
      </section>
    </header>
  );
}
export default Header;
