import React from 'react';
import logos from '../assets/logos.png';

function Social() {
  return (
    <section className="social">
      <div className="container social__container">
        <div className="social__text">
          <h3 className="social__text__title">
            Plus de 400 entreprises nous font confiance
          </h3>
          <button
            type="button"
            className="social__text__link"
            onClick={() => { document.location = 'https://ayomi.fr/cofunding/project/'; }}
          >
            Découvrir nos clients
          </button>
        </div>
        <div className="social__slideshow">
          <div
            className="social__slider"
            style={{
              background: `url(${logos})`,
              backgroundPosition: 'center',
              backgroundSize: '50%',
              backgroundRepeat: 'repeat-x',
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default Social;
