import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import domain from '../../global.js';

function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [timer, setTimer] = useState(null);

  const menuButton = useRef();

  useEffect(() => () => {
    if (timer) window.clearTimeout(timer);
  });

  return (
    <nav className="navbar">
      <div className="navbar__section">
        <button
          type="button"
          className="logo--ayomi backToHomePage"
          onClick={() => {
            if (document.location !== domain) {
              document.location = domain;
            }
          }}
        >
          Ayomi
        </button>
        <button
          type="button"
          className="
          body-2
          navbar__section__link
          navbar__section__link--border"
          onClick={() => { document.location = '/solution/'; }}
        >
          Notre solution
        </button>
        <button
          type="button"
          className="
          body-2
          navbar__section__link
          navbar__section__link--border"
          onClick={() => { document.location = '/cofunding/project/'; }}
        >
          Clients
        </button>
        <button
          type="button"
          className="
          body-2
          navbar__section__link
          navbar__section__link--border"
          onClick={() => { document.location = '/contact/'; }}
        >
          Contact
        </button>
      </div>
      <div className="navbar__section">
        <button
          type="button"
          className="
          body-2
          navbar__section__link
          navbar__section__link--classic"
          onClick={() => { document.location = '/compte/connexion/'; }}
        >
          Se connecter
        </button>
        <button
          type="button"
          className="button button--primary"
          onClick={() => { document.location = '/cofunding/ipoome/registerevent/#/'; }}
        >
          Débuter mon financement
        </button>
      </div>
      <div className="navbar--mobile">
        <div className="navbar__section--mobile">
          <button
            type="button"
            className="logo--ayomi backToHomePage"
            onClick={() => {
              if (document.location !== 'https://ayomi.fr') {
                document.location = 'https://ayomi.fr';
              }
            }}
          >
            Ayomi
          </button>
        </div>
        <div className="navbar__section--mobile">
          <button
            type="button"
            className="navbar__section__button"
            ref={menuButton}
            onClick={() => {
              if (!isMenuOpen) {
                const t = window.setTimeout(() => {
                  setIsMenuOpen(false);
                  menuButton.current.blur();
                }, 3000);
                setTimer(t);
              } else if (timer) {
                window.clearTimeout(timer);
                setTimer(null);
              }
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            <FontAwesomeIcon className="navbar__icon" icon={faBars} />
          </button>
        </div>
      </div>
      {
        (isMenuOpen) && (
          <div className="navbar__menu--mobile">
            <button
              type="button"
              className="
              body-2
              navbar__section__link
              navbar__section__link--border"
              onClick={() => { document.location = '/solution/'; }}
            >
              Notre solution
            </button>
            <button
              type="button"
              className="
              body-2
              navbar__section__link
              navbar__section__link--border"
              onClick={() => { document.location = '/cofunding/project/'; }}
            >
              Clients
            </button>
            <button
              type="button"
              className="
              body-2
              navbar__section__link
              navbar__section__link--border"
              onClick={() => { document.location = '/contact/'; }}
            >
              Contact
            </button>
          </div>
        )
      }
    </nav>
  );
}

export default NavBar;
