import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import freedgeBeauty from '../assets/freedgebeauty.png';
import freedgeBeautyLogo from '../assets/logo_1.png';
import vincent from '../assets/vincent.jpeg';
import vincentLogo from '../assets/vincent-logo.jpeg';
import espacielLogo from '../assets/Espaciel.jpeg';
import espacielCover from '../assets/espacielCover.jpg';

function Temoignage({ start }) {
  return (
    <section className="temoignage">
      <div className="container">
        <div className="home__title home__title--purple">
          <h6>
            Témoignages
          </h6>
          <h2>
            Des centaines d’entreprises nous font déjà confiance
          </h2>
          <div className="separator" />
        </div>
        <div className="temoignage__row">
          <div className="temoignage__item">
            <img
              className="temoignage__item__cover"
              src={freedgeBeauty}
              alt="Freedge Beauty s'est financée avec Ayomi"
            />
            <h4>Freedge Beauty</h4>
            <div className="temoignage__item__quote">
              <img
                className="temoignage__item__logo"
                src={freedgeBeautyLogo}
                alt="Freedge Beauty s'est financée avec Ayomi"
              />
              <p className="caption">
                “ Avec AYOMI, on a doublé notre objectif de financement ”
              </p>
            </div>
            <button
              type="button"
              className="button temoignage__item__button"
              onClick={() => start('https://www.youtube.com/embed/6eOo5iFPnHw')}
            >
              <FontAwesomeIcon className="icon" icon={faPlayCircle} />
              Regarder le témoignage
            </button>
          </div>
          <div className="temoignage__item">
            <img
              className="temoignage__item__cover"
              src={vincent}
              alt="Freedge Beauty s'est financée avec Ayomi"
            />
            <h4>Restaurant Vincent</h4>
            <div className="temoignage__item__quote">
              <img
                className="temoignage__item__logo"
                src={vincentLogo}
                alt="Freedge Beauty s'est financée avec Ayomi"
              />
              <p className="caption">
                “Ayomi m&apos;a permis de dynamiser mes ventes ”
              </p>
            </div>
            <button
              type="button"
              className="button temoignage__item__button"
              onClick={() => start('https://www.youtube.com/embed/yjIU0GiTN5w')}
            >
              <FontAwesomeIcon className="icon" icon={faPlayCircle} />
              Regarder le témoignage
            </button>
          </div>
          <div className="temoignage__item">
            <img
              className="temoignage__item__cover"
              src={espacielCover}
              alt="Freedge Beauty s'est financée avec Ayomi"
            />
            <h4>Espaciel</h4>
            <div className="temoignage__item__quote">
              <img
                className="temoignage__item__logo"
                src={espacielLogo}
                alt="Freedge Beauty s'est financée avec Ayomi"
              />
              <p className="caption">
                “
                Ce fut une véritable
                campagne de communication professionnelle.
                ”
              </p>
            </div>
            <button
              type="button"
              className="button temoignage__item__button"
              onClick={() => start('https://www.youtube.com/embed/3IeratWl_-I')}
            >
              <FontAwesomeIcon className="icon" icon={faPlayCircle} />
              Regarder le témoignage
            </button>
          </div>
        </div>
        <button
          type="button"
          className="button button--purple temoignage__button"
          onClick={() => { document.location = 'https://ayomi.fr/cofunding/project/'; }}
        >
          Voir plus de témoignages
        </button>
      </div>
    </section>
  );
}

export default Temoignage;
