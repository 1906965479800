import axios, { getCookie } from './clients/axiosClient';

axios.defaults.headers.put['X-CSRFToken'] = getCookie('csrftoken');
axios.defaults.headers.post['X-CSRFToken'] = getCookie('csrftoken');

export const postEmail = (mail) => {
  const fd = new FormData();
  fd.append('email', mail);
  return axios.post('https://ayomi.fr/inscription/news/ajax/', fd);
};
