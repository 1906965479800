import React, { useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import finance from '../assets/finance.svg';
import communication from '../assets/communication.svg';
import law from '../assets/law.svg';
import ri from '../assets/ri.svg';
import ui from '../assets/ui.svg';
import financeFile from '../assets/financeFile2.svg';
import path from '../assets/chemin.svg';
import pathColumn from '../assets/path-column.svg';
import pathLine from '../assets/path-line.svg';

function Processus() {
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);
  const [step6, setStep6] = useState(false);

  return (
    <section className="container processus">
      <div className="home__title home__title--purple">
        <h6>
          Processus
        </h6>
        <h2>
          Un outil tout-en-un simple, puissant et sécurisé
        </h2>
        <div className="separator" />
      </div>
      <div className="processus__main">
        <img className="processus__background--large" src={path} alt="Le processus ayomi" />
        <img className="processus__background--medium" src={pathColumn} alt="Le processus ayomi" />
        <img className="processus__background--small" src={pathLine} alt="Le processus ayomi" />
        <div className="processus__grid">
          <div className="processus__grid__cell">
            <div className="processus__inner__cell">
              <div className="processus__step">
                1
              </div>
              <img
                className="processus__illustration"
                src={finance}
                alt="Notre équipe vous aide avec vos finance"
              />
              <p
                className="body-2 processus__cell__title"
              >
                Un expert financier vous prend en main
              </p>
              <div
                className="caption processus__cell__background"
              >
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Analyse de votre situation
                </p>
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Identification des moyens de financement
                </p>
              </div>
            </div>
          </div>
          <div className="processus__grid__cell">
            <div className="processus__inner__cell">
              <div className="processus__step">
                2
              </div>
              <img
                className="processus__illustration"
                src={financeFile}
                alt="Notre équipe vous aide avec vos finance"
              />
              <p
                className="body-2 processus__cell__title"
              >
                Préparation du dossier de financement
              </p>
              <div
                className="caption processus__cell__background"
              >
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Projections financières
                </p>
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Valorisation de votre entreprise
                </p>
              </div>
            </div>
          </div>
          <div className="processus__grid__cell">
            <div className="processus__inner__cell">
              <div className="processus__step">
                3
              </div>
              <img
                className="processus__illustration"
                src={communication}
                alt="Notre équipe vous aide avec vos finance"
              />
              <p
                className="body-2 processus__cell__title"
              >
                Communication financière
              </p>
              <div
                className="caption processus__cell__background"
              >
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Design et création de votre thèse d’investissement
                </p>
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Communication digitale adaptée
                </p>
              </div>
            </div>
          </div>
          <div className="processus__grid__cell processus__cell--end c3">
            <div className="processus__inner__cell">
              <div className="processus__step">
                4
              </div>
              <img
                className="processus__illustration"
                src={ri}
                alt="Notre équipe vous aide avec vos finance"
              />
              <p
                className="body-2 processus__cell__title"
              >
                Support technique aux investisseurs
              </p>
              <div
                className="caption processus__cell__background"
              >
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Assistance téléphonique
                </p>
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Prise de rendez-vous
                </p>
              </div>
            </div>
          </div>
          <div className="processus__grid__cell c2">
            <div className="processus__inner__cell">
              <div className="processus__step">
                5
              </div>
              <img
                className="processus__illustration"
                src={law}
                alt="Notre équipe vous aide avec vos finance"
              />
              <p
                className="body-2 processus__cell__title"
              >
                Structuration juridique
              </p>
              <div
                className="caption processus__cell__background"
              >
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Convocation
                </p>
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Bulletin de souscription
                </p>
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Procès verbaux
                </p>
                <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                <p className="caption">
                  Holding
                </p>
              </div>
            </div>
          </div>
          <div className="processus__grid__cell processus__cell--end c1">
            <div className="processus__inner__cell">
              <div className="processus__step">
                6
              </div>
              <img
                className="processus__illustration"
                src={ui}
                alt="Notre équipe vous aide avec vos finance"
              />
              <p
                className="body-2 processus__cell__title"
              >
                Un espace de suivi post-investissement
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Processus;
